
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import { Watch } from "vue-property-decorator";

import UnitService from "../../services/sporteventCalculation/UnitService";
import Unit from "../../models/sporteventCalculation/Unit";

@Component({})
export default class UnitsComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: Unit[] = [];
  public fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "shortname",
      sortable: true,
      label: i18n.tc("labels.shortname"),
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  getAll(): void {
    UnitService.getAll().then((items: Unit[]) => {
      this.items = items;
      if (this.items) {
        this.totalRows = this.items.length;
      }
      this.loading = false;
    });
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }

  // CSV Import
  public csvFormData = new FormData();
  // eslint-disable-next-line
  handleFileUpload(event: any): void {
    //console.log(event);
    this.csvFormData.append(
      "competitionCsv",
      event.target.files[0],
      event.target.files[0].name
    );
  }
}
